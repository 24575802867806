.font-md {
    font-weight: 500;
}

.CheckboxDeliverRadioLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #A4A7B0;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
}

.CheckboxDeliverRadioLabel input {
    display: none;
}

.ChekcoutTerms {
    font-size: 14px;
    color: #676C7B;
}

.ChekcoutTerms a {
    text-decoration: none;
    color: #2490F9;
}
