/* #E4E8EC */
.TextInputCondensed {
    width: 100%;
    margin: 20px 0 0;
}

.TextInputCondensed label {
    display: block;
    color: #676C7B;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.TextInputCondensed input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #E4E8EC;
    font-size: 18px;
}

.TextInputCondensed input:focus {
    outline: none;
}

.TextInputCondensed small {
    display: block;
    color: #676C7B;
    font-size: 12px;
    font-weight: 400;
}