.font-md {
    font-weight: 500;
}

.ButtonTabOne {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #A4A7B0;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
}

.ButtonTabOne input {
    display: none;
}