@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.VideoModal {
  background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
}

.VideoModal .VideoModalContainer {
    position: relative;
}

.VideoModal .VideoModalIconCtn {
  border-bottom: #dedded 1px solid;
  padding: 14px 20px;
}

.VideoModal .VideoModalIconCtn h2{
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  margin: 0;
  color: #676C7B;
}


  .VideoModal .VideoModalIconCtn .closeIcon{
    color: #9593b3;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 11px;

  }


  .VideoModal .VideoModalMdlBody{
    padding: 15px;
    overflow-y: auto;
  }
  
 
  
  .VideoModal .VideoModalMdlBody p{
    font-size: 14px;
    color: #4C5E67;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    margin: 0;
    margin-bottom: 8px;
  }

  .VideoModal .VideoModalMdlBody a{
      color: #0B132B;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;

  }

  .VideoModal .VideoModalimg{
    width: 100%;
    object-fit: cover;
  }

  .VideoModal .VideoModaleventTitle{
    color: #0B132B;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif !important;

  }

  .VideoModal .VideoModaleventdetails{
    margin-top: 12px;
    max-width: 500px;
    margin-bottom: 16px;
  }

  .VideoModal .VideoModaleventdetailsinner{
    display: flex;
    align-items: center;
  }

  .VideoModal .VideoModaleventdetailsinner h4{
    color: #0B132B;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    margin-right: 5px;
  }

  .VideoModal .VideoModaleventdetails .VideoModalPrice {
    color: #0B132B;
    font-size: 20px;
    font-weight: bold;
  }

  .VideoModal .VideoModalBody p{
    color: #0B132B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    line-height: 30px;
  }

  .VideoModal .VideoModaleventdetailsinner a{
    color: #0077FF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
  }

  .VideoModal .VideoModaleventdetailsinner a:hover{
    text-decoration: underline;
  }

  .VideoModalFooter{
    background: #F7F9FA;
    padding: 16px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;

  }
 
 











