@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.bg-white {
    background-color: #FFF;
}

.footer-cart-container {
    position: fixed;
    right:   0;
    bottom:  0;
    left:    0;
    z-index: 2030;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-cart-bar {
    width: 100%;
    background-color: #FFF;
    border: 2px solid #00000017;
}

@media (min-width: 600px) {
    .footer-cart-container {
        padding: 0 24px;
    }
}
