:root {
    --toastify-toast-background: #33B651;
    --toastify-icon-color-success: #FFF;
    --toastify-toast-width: 400px;
}

.Toastify__toast {
    min-height: auto;
}

.Toastify__toast-theme--light {
    background-color: var(--toastify-toast-background) !important;
}

.Toastify__toast-icon {
    color: var(--toastify-icon-color-success);
}

.Toastify__close-button {
    color: #FFF;
    align-self: center !important;
}

.ToastSuccess {
    color: #FFF;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ToastSuccess a {
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
    margin-right: 10px;
}

