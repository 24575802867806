.VideoCardOne .VideoCardOneInfoContainer{
    text-decoration: none !important;
    cursor: pointer;
}


.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneavatar {
	overflow: hidden;
	position: relative;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneavatar img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    height: 100%;
}

.VideoCardOne .VideoCardOnehover_over {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background: #000;
    position: absolute;
    left: 0;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0;	
    
}

.VideoCardOne:hover .VideoCardOnehover_over{
	opacity: 0.5;	
}

.VideoCardOne .videoIcon{
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: absolute;
    left: 0;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0;
    
}

.VideoCardOne:hover .videoIcon{
    opacity: 1;
}

.VideoCardOne .videoIcon svg{
    font-size: 35px;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDetails{
	padding: 20px;
    height: 150px;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDesBox{
    padding: 20px 0px;
    padding-bottom: 0;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDetails h2 {
    font-size: 20px;
    margin: 0 !important;
}


.VideoCardOne .VideoCardOneInfoContainer .VideoCardOnereadmore {
    display: flex;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    text-decoration: none;
    margin: 0;
    align-items: center;
}

.VideoCardOne:hover .VideoCardOneInfoContainer .VideoCardOnereadmore {
    color:#635BFF !important;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOnereadmore svg{
	font-size: 15px !important;
	margin-left: 4px;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneboxed-grey:hover {
  box-shadow: -1px 9px 23px -7px hsla(0, 0%, 0%, 0.45);
  transition: all 1s ease 0s;
}

.VideoCardOne .bottomLine{
    height: 4px;
}

.border{
    border: #E4E8EC 1px solid;
}

.VideoCardOne:hover .underline{
    text-decoration: underline;
}

