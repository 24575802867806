.about-us-ul {
    list-style: none;
}

.about-us-ul li::before{
  content: "\2022";
  color: #F86635;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.composition {
  position: relative; 
}

.composition__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: .2rem;
    position: absolute;
    z-index: 10;
    transition: all .2s;
    outline-offset: 1rem; 
  }
  
  @media (max-width: 56.25em) {
      .composition__photo {
        outline-offset: 0.5rem; 
      } 
  }

@media (max-width: 56.25em) {
  .composition__photo {
    position: relative;
    float: left;
    width: 33.33333%;
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2); 
  } 
}

.composition__photo--p1 {
  left: 0;
  top: 4rem; 
}
@media (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    transform: scale(1.2); 
  } 
}

.composition__photo--p2 {
  right: 0;
  top: -2rem; 
}

@media (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -1rem;
    transform: scale(1.3);
    z-index: 15; 
  } 
}
.composition__photo--p3 {
  left: 20%;
  top: 12rem; 
}
@media (max-width: 56.25em) {
  .composition__photo--p3 {
    left: 0;
    top: 1rem;
    transform: scale(1.1); 
  } 
}
.composition__photo:hover {
  border-radius: 0;
  transform: scale(1.05) translateX(0.3rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  /* outline: 1.5rem solid #55c57a; */
  z-index: 20; 
}

@media (max-width: 56.25em) {
  .composition__photo:hover {
    transform: scale(1.35);
    /* outline: 1rem solid #55c57a;  */
  } 
}

.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.9); 
}

@media (max-width: 56.25em) {
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(1); 
  } 
}